<template>
	<div>
		<el-dialog
			title="Nuevo Delivery"
			:visible="showRegisterDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="70%"
		>
			<form autocomplete="off" @submit.prevent="submit" v-if="loaded">
				<v-row dense>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<div class="form-group">
							<label class="control-label">Canal de Delivery</label>
							<el-select v-model="form.channel_id">
								<el-option
									v-for="option in channels"
									:key="option.value"
									:value="option.value"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.channel_id"
								v-text="errors.channel_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<div class="form-group">
							<label class="control-label">Delivery por recoger</label>
							<el-select v-model="form.to_go">
								<el-option :key="1" :value="false" label="No"></el-option>
								<el-option :key="2" :value="true" label="Si"></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.to_go"
								v-text="errors.to_go[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-divider>CONTACTO</el-divider>
					</v-col>
					<v-col cols="12" xl="8" lg="8" md="8" sm="8">
						<div class="form-group">
							<label
								>Persona que recibe el pedido
								<el-input v-model="form.shipping.contact.name" required></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.contact.name']"
								v-text="errors['shipping.contact.name'][0]"
							></small>
						</div>
					</v-col>
					<!-- <v-col cols="12" xl="3" lg="6" md="12" sm="12">
						<div class="form-group">
							<label
								>Correo electrónico
								<el-input
									type="email"
									v-model="form.shipping.contact.email"
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.contact.email']"
								v-text="errors['shipping.contact.email'][0]"
							></small>
						</div>
					</v-col> -->
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<div class="form-group">
							<label
								>Teléfono
								<el-input
									v-model="form.shipping.contact.telephone"
									required
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.contact.telephone']"
								v-text="errors['shipping.contact.telephone'][0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<div class="form-group">
							<label>
								Dirección de entrega
								<el-input
									v-model="form.shipping.location.address"
									required
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.location.address']"
								v-text="errors['shipping.location.address'][0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<label
								>Rerefencia
								<el-input v-model="form.shipping.reference"></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.reference']"
								v-text="errors['shipping.reference'][0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<label
								>Número interior
								<el-input
									v-model="form.shipping.inside_number"
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['shipping.inside_number']"
								v-text="errors['shipping.inside_number'][0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-divider>FACTURACIÓN</el-divider>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.voucher }">
							<label class="control-label">¿Desea comprobante?</label>
							<el-select v-model="form.billing.voucher">
								<el-option :key="1" :value="false" label="No"></el-option>
								<el-option :key="2" :value="true" label="Si"></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors['billing.voucher']"
								v-text="errors['billing.voucher'][0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12" v-if="form.billing.voucher">
						<div class="form-group" :class="{ 'error--text': errors.voucher }">
							<label class="control-label">Tipo de comprobante</label>
							<el-select v-model="form.billing.document_type_id" @change="initCustomer">
								<el-option
									v-for="option in documentTypes"
									:key="option.value"
									:value="option.value"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors['billing.document_type_id']"
								v-text="errors['billing.document_type_id'][0]"
							></small>
						</div>
					</v-col>
					<v-col
						cols="12"
						xl="4"
						lg="4"
						md="4"
						sm="4"
						v-if="form.billing.document_type_id && form.billing.voucher"
					>
						<div class="form-group" v-if="form.billing.document_type_id == '01'">
							<label>
								Número
								<el-input v-model="form.billing.customer_number" :maxlength="11" dusk="number"  show-word-limit>
									<el-button type="primary" slot="append" :loading="loading_search_people" icon="el-icon-search" @click.prevent="searchSunat">
										SUNAT
									</el-button>
								</el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['billing.customer_number']"
								v-text="errors['billing.customer_number'][0]"
							></small>
						</div>
						<div class="form-group" v-else>
							<label>
								DNI (opcional)
								<el-input
									v-model="form.billing.customer_number"
									autocomplete="off"
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['billing.customer_number']"
								v-text="errors['billing.customer_number'][0]"
							></small>
						</div>
					</v-col>
					<v-col
						cols="12"
						xl="12"
						lg="12"
						md="12"
						sm="12"
						v-if="form.billing.document_type_id == '01' && form.billing.voucher"
					>
						<div class="form-group" :class="{ 'error--text': errors.voucher }">
							<label
								>Nombre ó Razón Social
								<el-input
									v-model="form.billing.customer_name"
									autocomplete="off"
								></el-input>
							</label>
							<small
								class="error--text"
								v-if="errors['billing.customer_name']"
								v-text="errors['billing.customer_name'][0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-divider>AGREGAR PEDIDOS</el-divider>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="mx-auto emb-card pr-2 pl-2">
							<v-row dense>
								<v-col cols="12" xl="5" lg="12" md="12" sm="12">
									<div class="form-group">
										<label class="control-label">Producto</label>
										<el-select
											v-model="form_item.item_id"
											filterable
											remote
											reserve-keyword
											placeholder="Buscar..."
											:remote-method="searchItems"
											@change="changeItem"
											:loading="loadingSearch"
											loading-text="Cargando..."
										>
											<el-option
												v-for="item in items"
												:key="item.id"
												:label="item.name"
												:value="item.id"
											></el-option>
										</el-select>
									</div>
								</v-col>
								<v-col cols="12" xl="3" lg="6" md="6" sm="6">
									<div class="form-group">
										<label class="control-label">Cantidad</label>
										<el-input
											v-model="form_item.quantity"
											class="input-text-right"
											ref="quantity"
											autofocus
										></el-input>
									</div>
								</v-col>
								<v-col cols="12" xl="3" lg="6" md="6" sm="6">
									<div class="form-group">
										<label class="control-label">Precio Unit.</label>
										<el-input
											v-model="form_item.unit_price_value"
											class="input-text-right"
										></el-input>
									</div>
								</v-col>
								<v-col cols="12" xl="1" lg="12" md="12" sm="12">
									<label>Agregar</label>
									<el-button
										type="primary"
										icon="el-icon-circle-plus"
										class="btn-block"
										@click.prevent="clickAddItem"
									></el-button>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">Producto</th>
										<th class="text-right" width="160px">Cantidad</th>
										<th class="text-right">Precio Unit.</th>
										<th class="text-right">Total</th>
										<th class="text-right">Acciones</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in form.items" :key="index">
										<template v-if="currentIndex != index">
											<td>{{ item.item.name }}</td>
											<td class="text-right">
												<el-input-number
													v-model="item.quantity"
													:min="1"
													:max="10"
													@input="calculateItemTotal(index)"
													controls-position="right"
													:precision="0"
													:step="1"
													size="mini"
												>
												</el-input-number>
											</td>
											<td class="text-right">
												<emb-currency-sign></emb-currency-sign>
												{{ item.unit_price }}
											</td>
											<td class="text-right">
												<emb-currency-sign></emb-currency-sign>
												{{ item.total }}
											</td>
											<td class="text-right align-middle">
												<v-btn
													fab
													dark
													x-small
													color="accent"
													class="ma-1"
													@click="getCurrentIndex(index)"
												>
													<v-icon dark>mdi-settings</v-icon>
												</v-btn>
											</td>
										</template>
										<template v-else>
											<td
												colspan="4"
												class="text-right align-middle pl-1 pr-1"
											>
												<el-input
													v-model="item.note"
													size="mini"
													placeholder="Nota para el chef"
												></el-input>
											</td>
											<td class="text-right align-middle">
												<v-btn
													class="ma-1"
													fab
													dark
													x-small
													color="error"
													@click="deleteProductFromCart(cart)"
												>
													<v-icon dark>mdi-delete</v-icon>
												</v-btn>
												<v-btn
													fab
													dark
													x-small
													color="success"
													@click="clearCurrentIndex"
												>
													<v-icon dark>mdi-check-all</v-icon>
												</v-btn>
											</td>
										</template>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
				<v-row dense class="justify-content-end" v-if="form.total > 0">
					<v-col cols="12" xl="6" lg="12" offset-xl="6">
						<v-simple-table dense>
							<template v-slot:default>
								<tbody>
									<tr class="h6" v-if="form.total > 0">
										<td colspan="2" class="text-right"><h5>Total:</h5></td>
										<td class="text-right">
											<h5>S/ {{ form.total }}</h5>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading">
							<template v-if="loading">
								Guardando...
							</template>
							<template v-else>
								Guardar
							</template>
						</el-button>
					</v-col>
				</v-row>
			</form>
		</el-dialog>
	</div>
</template>

<script>
// import { serviceNumber } from 'Mixins/functions';
import round from 'lodash/round';
import find from 'lodash/find';
import dayjs from 'dayjs';

export default {
	props: ['showRegisterDialog'],
	// mixins: [serviceNumber],
	data() {
		return {
			loading: false,
			loadingSearch: false,
			loading_search_people: false,
			loaded: false,
			resource: 'orders',
			items: [],
			currentIndex: null,
			errors: {},
			form: {},
			form_item: {},
			channels: [
				{ value: '02', name: 'WhatsApp' },
				{ value: '03', name: 'Facebook' },
				{ value: '04', name: 'Llamada telefónica' },
			],
			documentTypes: [
				{ value: '01', name: 'FACTURA ELECTRÓNICA' },
				{ value: '03', name: 'BOLETA DE VENTA ELECTRÓNICA' },
			],
		};
	},
	// mounted() {
	// 	window.addEventListener(
	// 		'keypress',
	// 		function(e) {
	// 			if (e.which == 43) {
	// 				this.clickAddItem();
	// 			}
	// 		}.bind(this)
	// 	);
	// },
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				prefix: 'PE',
				user_id: null,
				channel_id: '02',
				date_of_issue: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 2,
				currency_type_id: 'PEN',
				exchange_rate_sale: 0,
				total: 0,
				items: [],
				shipping: {
					contact: {
						name: null,
						email: null,
						telephone: null,
					},
					location: {
						address: null,
						latitude: null,
						longitude: null,
					},
					reference: null,
					inside_number: null,
				},
				billing: {
					voucher: false,
					document_type_id: null,
					amount: 0,
					customer_number: null,
					customer_name: null,
					customer: {}
				},
				type_sale: 'in_store',
				order_state_id: '02',
				to_go: false,
			};
			this.loaded = true;
		},
		initFormItem() {
			this.form_item = {
				item_id: null,
				item: {},
				note: null,
				quantity: 1,
				unit_price_value: 0,
				unit_price: 0,
				total: 0,
			};
			this.items = [];
		},
		initCustomer() {
			this.form.billing.customer_number = null;
			this.form.billing.customer_name = null;
			this.form.billing.customer = {
				type: 'customers',
				identity_document_type_id: '6',
				number: '',
				name: null,
				trade_name: null,
				country_id: 'PE',
				department_id: null,
				province_id: null,
				district_id: null,
				address: null,
				telephone: null,
				email: null,
				state: null,
				condition: null,
				perception_agent: false,
			}
		},
		async create() {
			this.initForm();
			this.initFormItem();
			this.initCustomer();
		},
		searchItems(input) {
			if (input.length > 2) {
				this.loadingSearch = true;
				let parameters = `input=${input}`;
				this.$http.get(`/${this.resource}/search/items?${parameters}`).then((response) => {
					this.items = response.data;
					this.loadingSearch = false;
				});
			} else {
				this.items = [];
			}
		},
		changeItem() {
			this.form_item.item = find(this.items, { id: this.form_item.item_id });

			this.form_item.unit_price_value = this.form_item.item.sale_unit_price;

			let unit_price = this.form_item.unit_price_value;

			this.form_item.unit_price = unit_price;
			this.form_item.item.unit_price = unit_price;
		},
		clickAddItem() {
			if (!this.form_item.item_id) {
				return this.$message.error('Error! selecciona un producto para continuar.');
			}

			if (!Number(this.form_item.quantity)) {
				return this.$message.error('Error! La cantidad ingresada es incorrrecto.');
			}

			if (!Number(this.form_item.unit_price) || this.form_item.unit_price <= 0) {
				return this.$message.error('Error! el precio unitario ingresado es incorrrecto.');
			}

			this.form.items.push(JSON.parse(JSON.stringify(this.form_item)));

			this.initFormItem();
			this.$refs.quantity.$el.getElementsByTagName('input')[0].focus();

			this.calculateTotal();
		},
		clickRemoveItem(index) {
			this.form.items.splice(index, 1);
			this.calculateTotal();
		},
		calculateItemTotal(index) {
			let item = this.form.items[index];
			let total = round(item.unit_price * item.quantity, 2);

			this.form.items[index].total = total;

			this.calculateTotal();
		},
		calculateTotal() {
			let total = 0;
			for (const item of this.form.items) {
				total += round(item.unit_price * item.quantity, 2);
			}
			this.form.total = total;
		},
		getCurrentIndex(index) {
			this.currentIndex = index;
		},
		clearCurrentIndex() {
			this.currentIndex = null;
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		searchSunat() {
			if(this.form.billing.customer_number === '') {
                this.$message.error('Ingresar el número a buscar')
                return
            }

			return new Promise((resolve) => {
                this.loading_search_people = true
                this.$http.get(`/service/ruc/${this.form.billing.customer_number}`)
                    .then(response => {
                        let res = response.data
                        if (res.success) {
                            this.form.billing.customer_name = res.data.nombre_o_razon_social
                            
							this.form.billing.customer.number = this.form.billing.customer_number;
							this.form.billing.customer.name = this.form.billing.document_type_id === '03' ? res.data.nombre_completo : res.data.nombre_o_razon_social;
							this.form.billing.customer.trade_name = this.form.billing.document_type_id === '01' ? res.data.nombre_o_razon_social : '';

							this.form.billing.customer.address = res.data.direccion;
							this.form.billing.customer.department_id = res.data.ubigeo && res.data.ubigeo[0] != '-' ? res.data.ubigeo[0] : null;
							this.form.billing.customer.province_id = res.data.ubigeo && res.data.ubigeo[1] != '-' ? res.data.ubigeo[1] : null;
							this.form.billing.customer.district_id = res.data.ubigeo && res.data.ubigeo[2] != '-' ? res.data.ubigeo[2] : null;
							this.form.billing.customer.condition = res.data.condicion;
							this.form.billing.customer.state = res.data.estado;

							this.$message.success('Datos del cliente han sido encontrados!');
							console.log(this.form.billing.customer);
                        } else {
                            this.$message.error(res.message)
                        }
                        resolve()
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
                    .then(() => {
                        this.loading_search_people = false
                    })
            })
		},
		close() {
			this.$emit('update:showRegisterDialog', false);
			this.initForm();
		},
	},
};
</script>
